
export default [
  {
    id: "1-1",
    title: "簿記とは",
    categoryId: 1,
    page: 42
  },
  {
    id: "1-2",
    title: "簿記の基本用語",
    categoryId: 1,
    page: 42
  },
  {
    id: "1-3",
    title: "仕訳の作り方",
    categoryId: 1,
    page: 42
  },
  {
    id: "1-4",
    title: "簿記の5要素と貸借対照表、損益計算書",
    categoryId: 1,
    page: 42
  },
  {
    id: "2-1",
    title: "現金取引",
    categoryId: 2,
    page: 43
  },
  {
    id: "2-2",
    title: "掛け取引",
    categoryId: 2,
    page: 43
  },
  {
    id: "2-3",
    title: "クレジット払いによる売上げ",
    categoryId: 2,
    page: 43
  },
  {
    id: "2-4",
    title: "商品券による売上げ",
    categoryId: 2,
    page: 43
  },
  {
    id: "3-1",
    title: "返品",
    categoryId: 3,
    page: 44
  },
  {
    id: "3-2",
    title: "諸掛り",
    categoryId: 3,
    page: 44
  },
  {
    id: "3-3",
    title: "前払金と前受金",
    categoryId: 3,
    page: 44
  },
  {
    id: "3-4",
    title: "消費税",
    categoryId: 3,
    page: 44
  },
  {
    id: "4-1",
    title: "簿記上の現金",
    categoryId: 4,
    page: 45
  },
  {
    id: "4-2",
    title: "現金過不足",
    categoryId: 4,
    page: 45
  },
  {
    id: "4-3",
    title: "普通預金と定期預金",
    categoryId: 4,
    page: 45
  },
  {
    id: "4-4",
    title: "当座預金",
    categoryId: 4,
    page: 46
  },
  {
    id: "4-5",
    title: "当座借越",
    categoryId: 4,
    page: 46
  },
  {
    id: "4-6",
    title: "小口現金",
    categoryId: 4,
    page: 46
  },
  {
    id: "4-7",
    title: "現金・預金に関する証ひょうの読み取り",
    categoryId: 4,
    page: 46
  },
  {
    id: "5-1",
    title: "約束手形",
    categoryId: 5,
    page: 47
  },
  {
    id: "5-2",
    title: "電子記録債権(債務)",
    categoryId: 5,
    page: 47
  },
  {
    id: "6-1",
    title: "貸付金と借入金",
    categoryId: 6,
    page: 47
  },
  {
    id: "6-2",
    title: "手形貸付金と手形借入金",
    categoryId: 6,
    page: 48
  },
  {
    id: "6-3",
    title: "仮払金",
    categoryId: 6,
    page: 48
  },
  {
    id: "6-4",
    title: "仮受金",
    categoryId: 6,
    page: 48
  },
  {
    id: "7-1",
    title: "立替金と給料の支払い",
    categoryId: 7,
    page: 48
  },
  {
    id: "7-2",
    title: "給料の支払い",
    categoryId: 7,
    page: 49
  },
  {
    id: "8-1",
    title: "貸倒引当金の設定、貸倒れの処理、償却債権取立益",
    categoryId: 8,
    page: 49
  },
  {
    id: "9-1",
    title: "有形固定資産の購入",
    categoryId: 9,
    page: 49
  },
  {
    id: "9-2",
    title: "減価償却",
    categoryId: 9,
    page: 50
  },
  {
    id: "9-3",
    title: "有形固定資産の売却",
    categoryId: 9,
    page: 50
  },
  {
    id: "9-4",
    title: "未払金と未収入金",
    categoryId: 9,
    page: 50
  },
  {
    id: "9-5",
    title: "有形固定資産の賃借",
    categoryId: 9,
    page: 51
  },
  {
    id: "10-1",
    title: "株式の発行",
    categoryId: 10,
    page: 51
  },
  {
    id: "11-1",
    title: "消耗品",
    categoryId: 11,
    page: 51
  },
  {
    id: "11-2",
    title: "通信費と租税公課",
    categoryId: 11,
    page: 51
  },
  {
    id: "11-3",
    title: "収益・費用の未収・未払い",
    categoryId: 11,
    page: 52
  },
  {
    id: "11-4",
    title: "収益・費用の前受け・前払い",
    categoryId: 11,
    page: 52
  },
  {
    id: "12-1",
    title: "仕訳帳と総勘定元帳",
    categoryId: 12,
    page: 53
  },
  {
    id: "12-2",
    title: "小口現金出納帳①",
    categoryId: 12,
    page: 54
  },
  {
    id: "12-3",
    title: "小口現金出納帳②",
    categoryId: 12,
    page: 54
  },
  {
    id: "12-4",
    title: "仕入帳と売上帳",
    categoryId: 12,
    page: 55
  },
  {
    id: "12-5",
    title: "受取手形記入帳と支払手形記入帳",
    categoryId: 12,
    page: 56
  },
  {
    id: "13-1",
    title: "売掛金元帳と買掛金元帳",
    categoryId: 13,
    page: 56
  },
  {
    id: "13-2",
    title: "商品有高帳①",
    categoryId: 13,
    page: 59
  },
  {
    id: "13-3",
    title: "商品有高帳②",
    categoryId: 13,
    page: 59
  },
  {
    id: "13-4",
    title: "固定資産台帳",
    categoryId: 13,
    page: 60
  },
  {
    id: "13-5",
    title: "補助簿の選択",
    categoryId: 13,
    page: 61
  },
  {
    id: "14-1",
    title: "三伝票制",
    categoryId: 14,
    page: 62
  },
  {
    id: "14-2",
    title: "仕訳日計表の作成、各元帳への転記",
    categoryId: 14,
    page: 63
  },
  {
    id: "15-1",
    title: "試算表の作成①",
    categoryId: 15,
    page: 66
  },
  {
    id: "15-2",
    title: "試算表の作成②",
    categoryId: 15,
    page: 68
  },
  {
    id: "16-1",
    title: "売上原価の算定",
    categoryId: 16,
    page: 71
  },
  {
    id: "16-2",
    title: "法人税等の計上",
    categoryId: 16,
    page: 71
  },
  {
    id: "17-1",
    title: "精算表の作成①",
    categoryId: 17,
    page: 72
  },
  {
    id: "17-2",
    title: "精算表の作成②",
    categoryId: 17,
    page: 74
  },
  {
    id: "17-3",
    title: "精算表の作成③",
    categoryId: 17,
    page: 77
  },
  {
    id: "17-4",
    title: "財務諸表の作成",
    categoryId: 17,
    page: 80
  },
  {
    id: "17-5",
    title: "勘定の締め切り①",
    categoryId: 17,
    page: 85
  },
  {
    id: "17-6",
    title: "勘定の締め切り②",
    categoryId: 17,
    page: 85
  },
  {
    id: "17-7",
    title: "剰余金の配当と処分",
    categoryId: 17,
    page: 86
  },
]