
export default [
  {
    "id": 1,
    "name": "簿記の基礎",
    "sort": 1,
  },
  {
    "id": 2,
    "name": "商品売買①",
    "sort": 2,
  },
  {
    "id": 3,
    "name": "商品売買②",
    "sort": 3,
  },
  {
    "id": 4,
    "name": "現金と預金",
    "sort": 4,
  },
  {
    "id": 5,
    "name": "手形と電子記録債権（債務）",
    "sort": 5,
  },
  {
    "id": 6,
    "name": "貸付けと借入れ、仮払いと仮受け",
    "sort": 6,
  },
  {
    "id": 7,
    "name": "立替金と給料の支払い",
    "sort": 7,
  },
  {
    "id": 8,
    "name": "貸倒れと貸倒引当金",
    "sort": 8,
  },
  {
    "id": 9,
    "name": "有形固定資産と減価償却",
    "sort": 9,
  },
  {
    "id": 10,
    "name": "株式の発行",
    "sort": 10,
  },
  {
    "id": 11,
    "name": "その他の取引",
    "sort": 11,
  },
  {
    "id": 12,
    "name": "帳簿の記入①",
    "sort": 12,
  },
  {
    "id": 13,
    "name": "帳簿の記入②",
    "sort": 13,
  },
  {
    "id": 14,
    "name": "伝票制度",
    "sort": 14,
  },
  {
    "id": 15,
    "name": "試算表",
    "sort": 15,
  },
  {
    "id": 16,
    "name": "決算とその後①",
    "sort": 16,
  },
  {
    "id": 17,
    "name": "決算とその後②",
    "sort": 17,
  }
]